<script setup lang="ts">
import { DocumentType } from '@register'
import LeaseReviewDocumentUploader from '@register/components/Review/LeaseReviewDocumentUploader.vue'

interface Props {
  amendmentsOnly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  amendmentsOnly: false,
})
// EMITS
const emit = defineEmits<{
  close: []
  confirm: []
}>()

// STATE
const disabled = ref(false)

// UPLOADER
const documentUploader = ref<InstanceType<
  typeof LeaseReviewDocumentUploader
> | null>(null)

// DOCUMENT TYPE
const documentType = ref<DocumentType>()

const typeOptions = Object.values(DocumentType)
  .map((value) => ({
    label: title(value),
    value,
  }))
  .filter((option) => {
    if (props.amendmentsOnly) {
      return [
        DocumentType.OPTION_EXERCISE,
        DocumentType.EXTENSION,
        DocumentType.VARIATION,
        DocumentType.TRANSFER,
        DocumentType.SURRENDER,
      ].includes(option.value)
    } else {
      return ![
        DocumentType.UNKNOWN,
        DocumentType.LEASE,
        DocumentType.SUBLEASE,
      ].includes(option.value)
    }
  })
  .sort((a, b) => a.label.localeCompare(b.label))

// CLOSE
const confirm = useConfirm()
// Prevent closing while uploading
const onClose = () => {
  confirm.require({
    title: 'Are you sure you want to close?',
    body: 'You will lose any progress made on uploading documents to this lease.',
    confirm: () => {
      documentUploader.value?.uppy.cancelAll()
      emit('close')
    },
    bypass: () => !disabled.value,
  })
}
function finish() {
  const router = useRouter()
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  if (props.amendmentsOnly) {
    router.push(`/division/${divisionId}/lease/${leaseId}/amendments`)
  } else {
    router.push(`/division/${divisionId}/lease/${leaseId}/documents`)
  }
  emit('confirm')
}
</script>

<template>
  <ModalBase
    title="Add a document to this lease"
    modal-class="max-w-[650px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
    :close-on-click="false"
    @confirm="$emit('confirm')"
    @close="onClose"
  >
    <div class="relative">
      <FormKit
        v-model="documentType"
        type="listbox"
        label="Select the document type"
        :options="typeOptions"
        :searchable="true"
        :disabled="disabled"
      />
      <LeaseReviewDocumentUploader
        v-if="documentType"
        ref="documentUploader"
        :document-type="documentType"
        @upload="disabled = true"
        @upload-success="finish"
      />
    </div>

    <template #footer>
      <div>
        <!-- Empty template to remove footer -->
      </div>
    </template>
  </ModalBase>
</template>

<style lang="postcss" scoped>
.uppy-is-drag-over {
  &::before {
    @apply bg-primary-50/80 text-primary absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center rounded-lg border-4 border-dashed pb-10 text-center text-4xl font-bold backdrop-blur-3xl backdrop-saturate-[180%];
    content: attr(data-company-name);
  }

  &::after {
    @apply border-primary absolute bottom-1 left-1 right-1 top-0 z-10 flex items-center justify-center rounded-lg pt-10 text-center text-2xl font-bold text-black;
    content: 'Drag and drop PDF files here';
  }
}
</style>
